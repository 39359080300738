import { useEffect } from 'react'
import { gsap } from 'gsap'

export const useToggleTransition = (ref, { isVisible, selectors }) => {
  useEffect(() => {
    const poster = ref.current.querySelector(selectors.poster)

    if (isVisible) {
      if (poster) {
        gsap.to(poster, {
          opacity: 0,
          duration: 0.33,
          delay: 0.33,
        })
      }
    } else {
      if (poster) {
        gsap.to(poster, {
          opacity: 1,
          duration: 0.33,
        })
      }
    }
  }, [ref, isVisible, selectors.poster])
}
