import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { wrap } from '@popmotion/popcorn'
import { useGesture } from '@use-gesture/react'
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil'
import {
  readyState,
  defaultMusicState,
  projectMusicState,
  projectsState,
  bgDarkenedState,
} from '@/store'
import { ScrollPagnation } from '@/components/ScrollPagnation'
import { ProjectPoster } from '@/components/ProjectPoster'
import { SoundEffect } from '@/components/SoundEffect'
import { ProjectModal } from '@/components/ProjectModal'
import { useTransition } from './Home.gsap'
import * as styles from './Home.styles.scss'

const PAGINATION_DELAY = 1500 // time to wait between next/prev project
const OUT_DELAY = 1500 // time to wait after clicking close project

const Home = ({
  audioProjectHover,
  audioEnter,
  audioExit,
  audioScroll,
  isEnlarged,
  projects,
  scrollPrompt,
  // path,
  // title,
}) => {
  const ref = useRef()
  const isReady = useRecoilValue(readyState)
  const [direction, setDirection] = useState(1)
  // const [projectState, setProjectState] = useState(
  //   isEnlarged ? 'visible' : 'initial'
  // )
  const isAnimating = useRef(isEnlarged)

  const [audioEnterIsPlaying, setAudioEnterIsPlaying] = useState(false)
  const audioEnterPlayer = useRef({})

  const [audioExitIsPlaying, setAudioExitIsPlaying] = useState(false)
  const audioExitPlayer = useRef({})

  const [audioScrollIsPlaying, setAudioScrollIsPlaying] = useState(false)
  const [audioScrollIndex, setAudioScrollIndex] = useState(0)
  const audioScrollPlayer = useRef({})

  const [hasNavigatedProjects, setHasNavigatedProjects] = useState(false)

  // Global states
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [projectModal, setProjectModal] = useState(projects[0])
  const setIsPlayingProjectMusic = useSetRecoilState(projectMusicState)
  const setIsPlayingDefaultMusic = useSetRecoilState(defaultMusicState)
  const setIsBgDarkened = useSetRecoilState(bgDarkenedState)
  const [projectIndex, setProjectIndex] = useRecoilState(projectsState)

  useEffect(() => {
    setIsBgDarkened(false)
    setIsPlayingDefaultMusic(!isEnlarged)
    setIsPlayingProjectMusic(isEnlarged)
  }, [
    isEnlarged,
    setIsBgDarkened,
    setIsPlayingDefaultMusic,
    setIsPlayingProjectMusic,
  ])

  const paginate = (newDirection) => {
    if (!isAnimating.current) {
      const projectID = wrap(0, projects.length, projectIndex + newDirection)
      // setCurrent([projectID, newDirection])
      isAnimating.current = true
      setProjectIndex(projectID)
      setDirection(newDirection)
      setHasNavigatedProjects(true)

      if (audioScrollPlayer.current.player) {
        setAudioScrollIndex((prevState) => prevState + newDirection)
        setAudioScrollIsPlaying(true)
        audioScrollPlayer.current.player.seekTo(0)
      }

      setTimeout(() => {
        isAnimating.current = false
      }, PAGINATION_DELAY)
    }
  }

  const handleWheel = (yMovement) => {
    if (yMovement >= 1) {
      paginate(1)
    } else if (yMovement <= -1) {
      paginate(-1)
    }
  }

  const gestures = useGesture(
    {
      onWheel: ({ movement }) => handleWheel(movement[1]),
      onDrag: ({ movement }) => handleWheel(-movement[1]),
    },
    {
      // wheel: { bounds: { top: -1, bottom: 1 } },
      drag: {
        // bounds: { top: -1, bottom: 1 }, // causes only two projects to be scrollable
        threshold: 30,
      },
    }
  )

  const handleOpenProject = (project) => {
    if (!isAnimating.current) {
      if (audioEnterPlayer.current.player) {
        setAudioEnterIsPlaying(true)
        audioEnterPlayer.current.player.seekTo(0)
      }
      setIsModalOpen(true)
      setProjectModal(project)
      isAnimating.current = true
      setIsPlayingDefaultMusic(false)
      setIsPlayingProjectMusic(true)
    }
  }

  const handleCloseProject = () => {
    setIsModalOpen(false)
    setIsPlayingDefaultMusic(true)
    setIsPlayingProjectMusic(false)

    if (audioExitPlayer.current.player) {
      setAudioExitIsPlaying(true)
      audioExitPlayer.current.player.seekTo(0)
    }

    setTimeout(() => {
      isAnimating.current = false
    }, OUT_DELAY)
  }

  useTransition(ref)

  const handleJumpTo = (index) => {
    if (!isAnimating.current) {
      isAnimating.current = true
      setProjectIndex(index)
      setHasNavigatedProjects(true)

      if (audioScrollPlayer.current.player) {
        setAudioScrollIsPlaying(true)
        audioScrollPlayer.current.player.seekTo(0)
      }

      setTimeout(() => {
        isAnimating.current = false
      }, PAGINATION_DELAY)
    }
  }

  return (
    <div className={styles.Home} ref={ref}>
      <ProjectModal
        project={projectModal}
        isOpen={isModalOpen}
        onClickClose={handleCloseProject}
      />
      {audioEnter && (
        <SoundEffect
          ref={audioEnterPlayer}
          url={audioEnter}
          isPlaying={audioEnterIsPlaying}
        />
      )}
      {audioExit && (
        <SoundEffect
          ref={audioExitPlayer}
          url={audioExit}
          isPlaying={audioExitIsPlaying}
        />
      )}
      {!!audioScroll.length && (
        <SoundEffect
          ref={audioScrollPlayer}
          url={audioScroll[wrap(0, audioScroll.length, audioScrollIndex)]}
          isPlaying={audioScrollIsPlaying}
        />
      )}
      {/* <Project
        {...projects[projectIndex]}
        isHeadingVisible={false}
        isOverlayMode
        onClickClose={handleCloseProject}
        state={projectState}
      /> */}
      <div className={styles.projects} {...gestures()}>
        {!!projects.length && (
          <div className={styles.projects}>
            {projects.map((item, index) => (
              <ProjectPoster
                key={item.uid}
                direction={direction}
                cta={item.cta}
                headingLines={item.headingLines}
                heroAspectRatio={item.media?.aspectRatio}
                hoverSound={audioProjectHover}
                onClick={() => handleOpenProject(projects[index])}
                state={
                  projectIndex === index && isReady ? 'visible' : 'hidden'
                  // ? projectState === 'visible'
                  //   ? 'active'
                  //   : 'visible'
                  // : 'hidden'
                }
              />
            ))}
          </div>
        )}
      </div>
      {scrollPrompt && (
        <ScrollPagnation
          scrollPrompt={scrollPrompt}
          hasScrolled={hasNavigatedProjects}
          isVisible={true} // ={projectState !== 'visible'}
          progress={projectIndex / (projects.length - 1)}
          totalItems={projects.length}
          activeItem={projectIndex}
          onClickDot={(index) => {
            handleJumpTo(index)
          }}
        />
      )}
    </div>
  )
}

Home.defaultProps = {
  audioExit: [],
  audioScroll: [],
  isEnlarged: false,
  projects: [],
  // title: 'Projects',
}

Home.propTypes = {
  audioProjectHover: PropTypes.string,
  audioEnter: PropTypes.string,
  audioExit: PropTypes.string,
  audioScroll: PropTypes.array,
  isEnlarged: PropTypes.bool,
  projects: PropTypes.array,
  scrollPrompt: PropTypes.string,
  // path: PropTypes.string,
  // title: PropTypes.string,
}

export default Home
