// extracted by mini-css-extract-plugin
export var Block = "t_Z";
export var aniSpin = "t_d";
export var bg = "t_5";
export var bgBottom = "t_2";
export var bgTop = "t_1";
export var blackBgColor = "t_4";
export var hasPaddingBottom = "t_7";
export var hasPaddingTop = "t_6";
export var transparentBgColor = "t_0";
export var whiteBgColor = "t_3";