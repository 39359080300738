import { useEffect } from 'react'
import { gsap } from 'gsap'
import { useRecoilValue } from 'recoil'
import { transitionState, readyState } from '@/store'

export const useTransition = (ref) => {
  const transition = useRecoilValue(transitionState)
  const isReady = useRecoilValue(readyState)

  useEffect(() => {
    const tl = gsap.timeline()
    if (transition === 'ready' || (transition === null && isReady)) {
      tl.to(ref.current, {
        opacity: 1,
        delay: 0.75,
        overwrite: true,
      })
    } else if (transition === 'in') {
      tl.to(ref.current, {
        opacity: 0,
        delay: 0.25,
        overwrite: true,
      })
    }
  }, [ref, transition, isReady])
}
