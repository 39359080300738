import { useEffect, useState } from 'react'
import { gsap, Cubic } from 'gsap'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store'

const MOBILE_OFFSET = 70

export const useTransition = (
  ref,
  { selectors, state, totalHeadingLines, headingHeight, heroHeight }
) => {
  const { heading } = selectors
  const { device } = useRecoilValue(browserState)
  const [isReady, setIsReady] = useState()

  useEffect(() => {
    const $heading = ref.current.querySelector(heading)

    // Visible
    if (state === 'visible') {
      const tl = gsap.timeline()
      tl.to($heading, {
        autoAlpha: 1,
        y: 1,
        duration: 1,
      })
      tl.call(
        () => {
          setIsReady(true)
        },
        null,
        '=+1'
      )
    }

    // Hidden
    else if (state === 'hidden') {
      setIsReady(false)
      gsap.to($heading, { autoAlpha: 0, y: 0, duration: 1 })
    }

    // Active
    else if (state === 'active') {
      setIsReady(false)
      gsap.fromTo(
        $heading,
        {
          y: 0,
          autoAlpha: 1,
        },
        {
          y:
            device === 'mobile'
              ? -heroHeight / 2 -
                headingHeight / totalHeadingLines +
                MOBILE_OFFSET
              : -heroHeight / 2 - headingHeight / totalHeadingLines,
          duration: 1,
          delay: 0.125,
          ease: Cubic.easeOut,
        }
      )
    }
  }, [
    ref,
    heading,
    state,
    totalHeadingLines,
    headingHeight,
    heroHeight,
    device,
  ])

  return isReady
}
