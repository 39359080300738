import { getFluidGatsbyImage } from 'gatsby-storyblok-image'

/**
 * Get Image data from Storyblok
 *
 * See options here:
 * https://github.com/bejamas/gatsby-storyblok-image
 */

export const getFluidImage = (filename, options = {}) => {
  if (filename) {
    const imageObject = getFluidGatsbyImage(filename, options)

    if (imageObject) {
      return {
        mediaType: 'image',
        src: imageObject.src,
        fileType: filename.split(/[#?]/)[0].split('.').pop().trim(),
        srcset: imageObject.srcSet || null,
        sizes: imageObject.sizes || null,
        aspectRatio: imageObject.aspectRatio || null,
        placeholder: imageObject.placeholder || null,
        alt: imageObject.alt,
        width: imageObject.width,
        height: imageObject.height,
        srcSetWebp: imageObject.srcSetWebp,
        srcWebp: imageObject.srcWebp,
      }
    }
  }
  return null
}
