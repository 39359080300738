import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'
import LazyLoad from 'vanilla-lazyload'
import PropTypes from 'prop-types'
import objectFitImages from 'object-fit-images'
import * as styles from './Img.styles.scss'

const LAZY_CLASS = 'lazy'
const IS_BROWSER = typeof window !== 'undefined'

const Img = ({
  className,
  src,
  srcset,
  srcSetWebp,
  sizes,
  alt,
  placeholder,
  objectFit,
  objectPosition,
  isFull,
  isLazy,
  aspectRatio,
  bgColor,
  fileType,
}) => {
  const ref = useRef()

  useEffect(() => {
    if (!IS_BROWSER) return // don't run code on SSR

    // https://codesandbox.io/s/20306yk96p?file=/src/lazy-image.js:490-495
    // only initialize lazy loading one time for the app
    if (!document.lazyLoadInstance) {
      document.lazyLoadInstance = new LazyLoad({
        elements_selector: `.${LAZY_CLASS}`,
        // optional callback on loaded
        // callback_loaded: (el) => {
        //   el.parentElement.setAttribute('data-loaded', 'true')
        //   if (typeof onLoaded === 'function') onLoaded()
        // },
      })
    }

    // update lazyLoad after first rendering of every image
    document.lazyLoadInstance.update()

    // activate oject fit polyfill for this image
    if (objectFit || objectPosition) {
      objectFitImages(ref.current)
    }
  }, [src, objectFit, objectPosition])

  // required for object-fit-images
  const objectFitPolyfill =
    (objectFit || objectPosition) &&
    `"${
      (objectFit ? `object-fit: ${objectFit};` : '') +
      (objectPosition ? `object-position: ${objectPosition};` : '')
    }"`

  if (src) {
    return (
      <div
        key={src}
        className={classNames(styles.Img, className, {
          [styles.isFull]: !!isFull,
        })}
        style={{
          paddingTop: !!aspectRatio && !isFull && `${100 / aspectRatio}%`,
          backgroundColor: !['svg', 'png', 'gif'].includes(fileType)
            ? bgColor
            : null,
        }}
      >
        <picture>
          {srcSetWebp && (
            <source
              type="image/webp"
              {...(isLazy
                ? {
                    'data-srcset': srcSetWebp,
                    'data-sizes': sizes ? sizes : null,
                  }
                : {
                    srcset: srcSetWebp,
                    sizes: sizes ? sizes : null,
                  })}
            />
          )}
          <img
            alt={alt || ''}
            style={{
              objectFit,
              objectPosition,
              fontFamily: objectFitPolyfill,
            }}
            ref={ref}
            className={classNames(styles.img, {
              [styles.isFull]: !!aspectRatio,
              [`${LAZY_CLASS}`]: !!isLazy,
            })}
            {...(isLazy
              ? {
                  'data-src': src,
                  'data-srcset': srcset ? srcset : null,
                  'data-sizes': sizes ? sizes : null,
                }
              : {
                  src: src,
                  srcSet: srcset ? srcset : null,
                  sizes: sizes ? sizes : null,
                })}
          />
        </picture>
        {!!placeholder && isLazy && (
          <img
            src={placeholder}
            alt={alt || ''}
            className={styles.placeholder}
          />
        )}
      </div>
    )
  }

  return null
}

Img.defaultProps = {
  alt: '',
  isLazy: true,
  bgColor: 'rgba(0,0,0,0.05)',
}

Img.propTypes = {
  className: PropTypes.string,
  alt: PropTypes.string,
  aspectRatio: PropTypes.number,
  isFull: PropTypes.bool,
  objectFit: PropTypes.string,
  objectPosition: PropTypes.string,
  placeholder: PropTypes.string,
  sizes: PropTypes.string,
  src: PropTypes.string,
  srcset: PropTypes.string,
  fileType: PropTypes.string,
  bgColor: PropTypes.string,
  isLazy: PropTypes.bool,
}

export { Img }
