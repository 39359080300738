import React from 'react'
import PropTypes from 'prop-types'
import { RichText } from '@/components/RichText'
import { BigHeading } from '@/components/BigHeading'
import { ProjectHero } from '@/components/ProjectHero'
import * as styles from './Project.styles.scss'

const Project = ({
  description,
  headingLines = [],
  media,
  isDisabled = false,
}) => {
  return (
    <div className={styles.Project}>
      <div className={styles.inner}>
        <div className={styles.heading}>
          <BigHeading lines={headingLines} />
        </div>
        <ProjectHero media={media} isDisabled={isDisabled} />
        {description && (
          <div className={styles.description}>
            <RichText>{description}</RichText>
          </div>
        )}
      </div>
    </div>
  )
}

Project.propTypes = {
  description: PropTypes.node,
  headingLines: PropTypes.array,
  media: PropTypes.object,
}

export default Project
