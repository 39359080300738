import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Block } from '@/components/Block'
import { RichText } from '@/components/RichText'
import { Link } from '@/components/Link'
import { Container } from '@/components/Container'
import { useTransition } from './Text.gsap'
import * as styles from './Text.styles.scss'
import classNames from 'classnames'

const Text = ({ blocks }) => {
  const ref = useRef()
  useTransition(ref)

  return (
    <Block>
      <div className={styles.main} ref={ref}>
        <Container>
          {!!blocks.length && (
            <div
              className={classNames(styles.blocks, {
                [styles.col2]: blocks?.length > 1,
              })}
            >
              {blocks.map((block) => (
                <div key={block.uid} className={styles.block}>
                  {block.heading && (
                    <h3 className={styles.block__heading}>
                      <RichText>{block.heading}</RichText>
                    </h3>
                  )}
                  {block.description && (
                    <div
                      className={classNames(styles.block__bodyText, {
                        [styles.large]: blocks?.length === 1,
                      })}
                    >
                      <RichText>{block.description}</RichText>
                    </div>
                  )}
                  {!!block.links?.length && (
                    <div className={styles.links}>
                      {block.links.map((link) => {
                        return (
                          <div key={link.uid} className={styles.links__item}>
                            <Link to={link.url} className={styles.links__cta}>
                              {link.title}
                            </Link>
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </Container>
      </div>
    </Block>
  )
}

Text.defaultProps = {
  blocks: [],
}

Text.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
}

export { Text }
