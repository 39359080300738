import { useEffect } from 'react'
import { gsap } from 'gsap'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { bgDarkenedState, transitionState, readyState } from '@/store'

export const useTransition = (ref, { selectors }) => {
  const { header } = selectors
  const setIsBgDarkened = useSetRecoilState(bgDarkenedState)
  const transition = useRecoilValue(transitionState)
  const isReady = useRecoilValue(readyState)

  useEffect(() => {
    setIsBgDarkened(true)
  }, [setIsBgDarkened])

  useEffect(() => {
    const $root = ref.current
    const $header = $root.querySelector(header)

    if ($header) {
      let tl = gsap.timeline()
      if (transition === 'ready' || (transition === null && isReady)) {
        tl.to($header, {
          opacity: 1,
          delay: 0.5,
          duration: 1,
          overwrite: true,
        })
      } else if (transition === 'in') {
        tl.to($header, { opacity: 0, overwrite: true })
      }
    }
  }, [ref, header, transition, isReady])
}
