import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store'
import { ProgressCircle } from '@/components/ProgressCircle'
import { ProgressDots } from '@/components/ProgressDots'
import { BtnScroll } from '@/components/BtnScroll'
import { useTransition } from './ScrollPagnation.gsap'
import * as styles from './ScrollPagnation.styles.scss'

const ScrollPagnation = ({
  scrollPrompt,
  hasScrolled,
  isVisible,
  progress,
  totalItems,
  activeItem,
  onClickDot,
}) => {
  const ref = useRef()
  const { device } = useRecoilValue(browserState)

  useTransition(ref, {
    isVisible,
    hasScrolled,
    selectors: {
      progress: `.${styles.progress}`,
    },
  })

  return (
    <div className={styles.ScrollPagnation} ref={ref}>
      <div className={styles.btnScroll}>
        <BtnScroll isVisible={!hasScrolled && isVisible}>
          {scrollPrompt}
        </BtnScroll>
      </div>
      <div className={styles.progress}>
        {totalItems > 6 || device === 'mobile' ? (
          <ProgressCircle progress={progress} radius={25} strokeWidth={2} />
        ) : (
          <ProgressDots
            total={totalItems}
            active={activeItem}
            onClick={onClickDot}
          />
        )}
      </div>
    </div>
  )
}

ScrollPagnation.defaultProps = {
  hasScrolled: false,
  isVisible: true,
  progress: 0,
}

ScrollPagnation.propTypes = {
  scrollPrompt: PropTypes.node,
  hasScrolled: PropTypes.bool,
  isVisible: PropTypes.bool,
  progress: PropTypes.number,
}

export { ScrollPagnation }
