/**
 * Simple calculation to return aspect ratio in decimal format
 */

export const getAspectRatio = (width, height) => {
  if (
    width &&
    typeof width === 'number' &&
    height &&
    typeof height === 'number'
  ) {
    // this is consistent with gatsby-storyblok-image
    // https://github.com/bejamas/gatsby-storyblok-image/blob/6d6ac81bb123cfc50d61d38a1d16b78175dc33c7/src/utils/getBasicImageProps.js#L3
    return width / height
  }

  return 0.56 // fallback
}
