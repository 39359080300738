import React from 'react'
import PropTypes from 'prop-types'
import { Img } from '@/components/Img'
import { VideoLoop } from '@/components/VideoLoop'
import { VideoPlayer } from '@/components/VideoPlayer'

const Media = ({ mediaType, ...props }) => {
  // Image: Basic Image
  if (mediaType === 'image') {
    return <Img {...props} />
  }

  // Video Loop: Muted video loop
  else if (mediaType === 'video_loop') {
    return <VideoLoop {...props} />
  }

  // Video Player: Click to play
  else if (mediaType === 'video_player') {
    return <VideoPlayer {...props} />
  }

  console.warn(`Media: format not supported: ${mediaType}`)

  return null
}

Media.propTypes = {
  mediaType: PropTypes.oneOf([
    'image',
    'video_loop',
    'video_player',
    'vimeo_loop',
  ]),
}

export { Media }
