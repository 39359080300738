import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import * as styles from './ProjectCta.styles.scss'
import { useTransition } from './ProjectCta.gsap'

const ProjectCta = ({
  children,
  ctaLabel,
  onClick,
  isClickable,
  isVisible,
}) => {
  const ref = useRef()

  useTransition(ref, {
    isVisible,
    selectors: {
      cta: `.${styles.cta}`,
    },
  })

  return (
    <button
      type="button"
      className={styles.ProjectCta}
      onClick={onClick}
      style={{ pointerEvents: isClickable ? 'auto' : 'none' }}
      ref={ref}
    >
      <div className={styles.inner}>{children}</div>
      <div className={styles.cta}>
        <div className={styles.cta__inner}>
          {ctaLabel}
          {/* <div className={styles.cta__line} /> */}
        </div>
      </div>
    </button>
  )
}

ProjectCta.defaultProps = {
  isClickable: true,
  isVisible: true,
}

ProjectCta.propTypes = {
  children: PropTypes.node.isRequired,
  ctaLabel: PropTypes.node,
  onClick: PropTypes.func,
  isClickable: PropTypes.bool,
  isVisible: PropTypes.bool,
}

export { ProjectCta }
