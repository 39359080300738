import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Block } from '@/components/Block'
import { Container } from '@/components/Container'
import { RichText } from '@/components/RichText'
import { Img } from '@/components/Img'
import { useTransition } from './Profiles.gsap'
import * as styles from './Profiles.styles.scss'

const Profiles = ({ heading, items }) => {
  const ref = useRef()
  useTransition(ref)

  return (
    <Block>
      <Container>
        <div ref={ref} className={styles.main}>
          {heading && <h3 className={styles.heading}>{heading}</h3>}
          {!!items?.length && (
            <div className={styles.items}>
              {items.map((item) => {
                return (
                  <div key={item.uid}>
                    <div className={styles.image}>
                      {item.image && (
                        <Img {...item.image} isFull objectFit="cover" />
                      )}
                    </div>
                    <div className={styles.header}>
                      {item.name && (
                        <div className={styles.name}>{item.name}</div>
                      )}
                      {item.role && <div>{item.role}</div>}
                    </div>
                    <div className={styles.body}>
                      <RichText>{item.body}</RichText>
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </Container>
    </Block>
  )
}

Profiles.propTypes = {
  heading: PropTypes.node,
  items: PropTypes.arrayOf(PropTypes.object),
}

export { Profiles }
