import { getRichText } from '@/storyblok/utils/getRichText'
import { getMedia } from '@/storyblok/utils/getMedia'
import { linkResolver } from '@/storyblok/utils/linkResolver'

export const getProject = (node) => {
  if (node.content) {
    const headingLines = node.content.heading_lines.map(({ text }) => text)

    return {
      title: node.name,
      uid: node.uuid,
      path: linkResolver(node.full_slug),
      headingLines,
      description: getRichText(node.content?.description),
      media: getMedia(node.content?.media),
    }
  }

  return null
}
