// extracted by mini-css-extract-plugin
export var aniSpin = "J_d";
export var block = "J_cg";
export var block__bodyText = "J_ck";
export var block__heading = "J_cj";
export var blocks = "J_cf";
export var col2 = "J_ch";
export var large = "J_cl";
export var links = "J_cm";
export var links__cta = "J_cq";
export var links__item = "J_cn";
export var links__link = "J_cp";
export var main = "J_c";