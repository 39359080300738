import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import * as styles from './ProgressDots.styles.scss'

const ProgressDots = ({ active, onClick, total }) => {
  const [dots, setDots] = useState([])

  const handleOnClick = (index) => {
    if (typeof onClick === 'function') onClick(index)
  }

  useEffect(() => {
    const outputArray = []
    for (let i = 0; i < total; i += 1) {
      outputArray.push({ id: `dot-${i}`, isActive: i === active })
    }
    setDots(outputArray)
  }, [active, total])

  return (
    <div className={styles.ProgressDots}>
      {dots.map((item, index) => {
        return (
          <button
            type="button"
            onClick={() => handleOnClick(index)}
            key={item.id}
            aria-label={`Select item ${index + 1} of ${total}`}
            className={classnames(styles.dot, {
              [styles.isActive]: item.isActive,
            })}
          >
            <div className={styles.icon} />
          </button>
        )
      })}
    </div>
  )
}

ProgressDots.defaultProps = {
  active: 0,
  onClick: () => {},
  total: 0,
}

ProgressDots.propTypes = {
  active: PropTypes.number,
  onClick: PropTypes.func,
  total: PropTypes.number,
}

export { ProgressDots }
