import React from 'react'
import PropTypes from 'prop-types'
import SbEditable from 'storyblok-react'
import { useProjects } from '@/storyblok/hooks/useProjects'
import { useSiteConfig } from '@/storyblok/hooks/useSiteConfig'
import { linkResolver } from '@/storyblok/utils/linkResolver'
import Home from '@/templates/Home'

const ArtistsStoryblok = ({ story }) => {
  const projects = useProjects(story.content?.projects, {
    cta: story.content?.project_cta,
  })
  const { audio } = useSiteConfig()

  return (
    <SbEditable content={story.content} key={story.content._uid}>
      <Home
        projects={projects}
        path={linkResolver(story.full_slug)}
        scrollPrompt={story.content?.scroll_prompt}
        title={story.name}
        audioEnter={audio.projectEnter}
        audioExit={audio.projectExit}
        audioProjectHover={audio.projectHover}
        audioScroll={audio.projectScroll}
      />
    </SbEditable>
  )
}

ArtistsStoryblok.propTypes = {
  story: PropTypes.object,
}

export default ArtistsStoryblok
