import React, { useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useRecoilState } from 'recoil'
import { mutedState } from '@/store'
import { Media } from '@/components/Media'
import { useHeroSize } from './useHeroSize'
import * as styles from './ProjectHero.styles.scss'

const ProjectHero = ({ media, isDisabled }) => {
  const prevMutedState = useRef(null)
  const [isMuted, setIsMuted] = useRecoilState(mutedState)

  const muteAudio = () => {
    prevMutedState.current = isMuted
    setIsMuted(true)
  }

  const unMuteAudio = useCallback(() => {
    if (prevMutedState.current !== null) {
      setIsMuted(prevMutedState.current)
    }
  }, [setIsMuted])

  const hasVideoPlayer = media?.mediaType === 'video_player'

  useEffect(() => {
    if (isDisabled) unMuteAudio()
  }, [isDisabled, unMuteAudio])

  return (
    <div className={styles.ProjectHero}>
      {media && !isDisabled && (
        <Media
          {...media}
          isDisabled={isDisabled}
          isFull
          objectFit="contain"
          objectPosition="center"
          onPlay={hasVideoPlayer ? () => muteAudio() : null}
          onPause={hasVideoPlayer ? () => unMuteAudio() : null}
        />
      )}
    </div>
  )
}

ProjectHero.defaultProps = {
  isDisabled: false,
}

ProjectHero.propTypes = {
  media: PropTypes.object,
  isDisabled: PropTypes.bool,
}

export { ProjectHero, useHeroSize }
