import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'
import { BigHeading } from '@/components/BigHeading'
import { ProjectCta } from '@/components/ProjectCta'
import { SoundHover } from '@/components/SoundHover'
import { useHeroSize } from '@/components/ProjectHero'
import { useTransition } from './ProjectPoster.gsap'
import * as styles from './ProjectPoster.styles.scss'

const ProjectPoster = ({
  direction,
  cta,
  headingLines,
  heroAspectRatio,
  hoverSound,
  onClick,
  state,
}) => {
  const ref = useRef()
  const heroSize = useHeroSize(heroAspectRatio)
  const hasClickEvent = typeof onClick === 'function'
  const [headingRef, headingSize] = useMeasure({ polyfill: ResizeObserver })

  const isReady = useTransition(ref, {
    state,
    totalHeadingLines: headingLines.length,
    headingHeight: headingSize.height,
    heroHeight: heroSize.height,
    selectors: {
      heading: `.${styles.heading__inner}`,
    },
  })

  return (
    <div className={styles.ProjectPoster} ref={ref}>
      <div className={styles.heading}>
        <div className={styles.heading__inner}>
          {hasClickEvent && (
            <SoundHover url={hoverSound} hasHover={isReady}>
              <ProjectCta
                ctaLabel={cta}
                onClick={onClick}
                isClickable={isReady}
                isVisible={state === 'visible'}
              >
                <div ref={headingRef} className={styles.bigHeading}>
                  <BigHeading
                    lines={headingLines}
                    direction={direction > 0 ? 'up' : 'down'}
                    state={state}
                    isAnimated
                  />
                </div>
              </ProjectCta>
            </SoundHover>
          )}
        </div>
      </div>
    </div>
  )
}

ProjectPoster.defaultProps = {
  direction: 0,
  cta: 'View Project',
  headingLines: [],
  heroAspectRatio: 16 / 9,
  state: 'initial',
}

ProjectPoster.propTypes = {
  direction: PropTypes.number,
  cta: PropTypes.node,
  headingLines: PropTypes.array,
  heroAspectRatio: PropTypes.number,
  hoverSound: PropTypes.string,
  onClick: PropTypes.func,
  state: PropTypes.string,
}

export { ProjectPoster }
