import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './ProgressCircle.styles.scss'

const ProgressCircle = ({ progress, strokeWidth, radius }) => {
  // https://codepen.io/JMChristensen/pen/Ablch

  const diameter = radius + radius + strokeWidth + strokeWidth
  const circumference = Math.PI * (radius * 2)
  const strokeOffset = (1 - progress) * circumference
  const radiusPlusStroke = strokeWidth * 2 + radius // radius - strokeWidth * 2

  const circleStyle = {
    transform: `translate(-${strokeWidth}px, -${strokeWidth}px)`,
  }

  return (
    <div
      className={styles.ProgressCircle}
      style={{ width: `${diameter}px`, height: `${diameter}px` }}
    >
      <svg
        width={diameter}
        height={diameter}
        viewport={`0 0 ${diameter} ${diameter}`}
      >
        <circle
          r={radius}
          cx={radiusPlusStroke}
          cy={radiusPlusStroke}
          strokeWidth={`${strokeWidth}px`}
          strokeDasharray={circumference}
          strokeDashoffset="0"
          style={circleStyle}
        />
        <circle
          r={radius}
          cx={radiusPlusStroke}
          cy={radiusPlusStroke}
          strokeWidth={`${strokeWidth}px`}
          strokeDasharray={circumference}
          strokeDashoffset={strokeOffset}
          style={circleStyle}
        />
      </svg>
    </div>
  )
}

ProgressCircle.defaultProps = {
  radius: 90,
  strokeWidth: 1,
  progress: 0,
}

ProgressCircle.propTypes = {
  radius: PropTypes.number,
  progress: PropTypes.number,
  strokeWidth: PropTypes.number,
}

export { ProgressCircle }
