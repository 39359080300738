import React, { useState, useRef } from 'react'
import ReactPlayer from 'react-player/lazy'
import PropTypes from 'prop-types'
import { BtnPlay } from '@/components/BtnPlay'
import { Media } from '@/components/Media'
import { useToggleTransition } from './VideoPlayer.gsap'
import * as styles from './VideoPlayer.styles.scss'

const VideoPlayer = ({ aspectRatio, poster, src, onPlay, onPause }) => {
  const ref = useRef()
  const [isPlaying, setIsPlaying] = useState(false)

  const handleClickPoster = () => setIsPlaying(true)
  const handleOnPlay = () => {
    if (typeof onPlay === 'function') onPlay()
  }
  const handleOnPause = () => {
    if (typeof onPlay === 'function') onPause()
  }
  const handleOnEnd = () => setIsPlaying(false)

  useToggleTransition(ref, {
    isVisible: isPlaying,
    selectors: { poster: `.${styles.poster}` },
  })

  return (
    <div className={styles.VideoPlayer} ref={ref}>
      <div
        className={styles.sizer}
        style={{ paddingTop: !!aspectRatio && `${100 / aspectRatio}%` }}
      />
      {!!poster && (
        <button
          type="button"
          className={styles.poster}
          aria-label="Play Video"
          onClick={handleClickPoster}
          style={{ pointerEvents: isPlaying ? 'none' : 'auto' }}
        >
          {poster && <Media {...poster} objectFit="cover" isFull />}
          <div className={styles.btnPlay}>
            <BtnPlay />
          </div>
        </button>
      )}
      {(isPlaying || !poster) && (
        <div className={styles.video}>
          <ReactPlayer
            onPlay={handleOnPlay}
            onPause={handleOnPause}
            onEnded={handleOnEnd}
            url={src}
            playing={isPlaying}
            muted={false}
            controls={true}
            width={null}
            height={null}
          />
        </div>
      )}
    </div>
  )
}

VideoPlayer.defaultProps = {
  aspectRatio: 9 / 16,
  isDisabled: true,
}

VideoPlayer.propTypes = {
  aspectRatio: PropTypes.number,
  poster: PropTypes.object,
  src: PropTypes.string,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
}

export { VideoPlayer }
