import { useEffect } from 'react'
import { gsap } from 'gsap'

export const useTransition = (ref, { selectors, isVisible }) => {
  const { cta } = selectors

  useEffect(() => {
    const $cta = ref.current.querySelector(cta)

    if (isVisible) {
      gsap.to($cta, { opacity: 1, delay: 1.5, duration: 0.75 })
    } else {
      gsap.to($cta, { opacity: 0, duration: 0.25 })
    }
  }, [ref, cta, isVisible])
}
