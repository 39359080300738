import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import * as styles from './BigHeading.styles.scss'
import { useTransition } from './BigHeading.gsap'

const BigHeading = ({ lines, isAnimated, direction, state }) => {
  const ref = useRef()

  useTransition(ref, {
    state,
    direction,
    selectors: {
      lines: `.${styles.lineAnimated}`,
    },
  })

  return (
    <h2 className={styles.BigHeading} ref={ref}>
      {lines.map((line, index) => {
        return (
          <span
            key={`big-heading-${index}`}
            className={isAnimated ? styles.lineAnimated : styles.line}
          >
            {line}
          </span>
        )
      })}
    </h2>
  )
}

BigHeading.defaultProps = {
  lines: [],
  isAnimated: false,
  direction: 'down',
  state: 'initial',
}

BigHeading.propTypes = {
  lines: PropTypes.array,
  isAnimated: PropTypes.bool,
  direction: PropTypes.oneOf(['up', 'down']),
  state: PropTypes.string,
}

export { BigHeading }
