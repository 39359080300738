import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import CloseSVG from './images/close.inline.svg'
import { useTransition } from './BtnClose.gsap'
import * as styles from './BtnClose.styles.scss'

const BtnClose = ({ appearance, onClick, isVisible }) => {
  const handleOnClick = () => {
    if (typeof onClick === 'function') onClick()
  }

  const ref = useRef()
  useTransition(ref, { isVisible })

  return (
    <button
      ref={ref}
      type="button"
      onClick={handleOnClick}
      className={styles.BtnClose}
      aria-label="Close"
      data-appearance={appearance}
      // initial={{ opacity: 0, scale: 0 }}
      // animate={{
      //   opacity: 1,
      //   scale: 1,
      //   transition: { delay: 0.5, duration: 0.35, ease: 'easeOut' },
      // }}
      // exit={{
      //   opacity: 0,
      //   scale: 0,
      //   transition: { delay: 0, duration: 0.35, ease: 'easeOut' },
      // }}
    >
      <span className={styles.bg} />
      <span className={styles.icon}>
        <CloseSVG />
      </span>
    </button>
  )
}

BtnClose.defaultProps = {
  appearance: 'default',
}

BtnClose.propTypes = {
  appearance: PropTypes.oneOf(['default', 'outline']),
  onClick: PropTypes.func,
  isVisible: PropTypes.bool,
}

export { BtnClose }
