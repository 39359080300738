import React from 'react'
import PropTypes from 'prop-types'
import SbEditable from 'storyblok-react'
import { getProject } from '@/storyblok/utils/getProject'
import Project from '@/templates/Project'

const ArtistStoryblok = ({ story }) => {
  const projectData = getProject(story)

  return (
    <SbEditable content={story.content} key={story.content._uid}>
      <Project {...projectData} state="visible" />
    </SbEditable>
  )
}

ArtistStoryblok.propTypes = {
  story: PropTypes.object,
}

export default ArtistStoryblok
