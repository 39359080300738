import { gsap, Circ } from 'gsap'
import { useEffect } from 'react'

const Y_SHIFT = 50

export const useTransition = (ref, { selectors, state, direction }) => {
  const { lines } = selectors

  useEffect(() => {
    const $lines = ref.current.querySelectorAll(lines)

    // Visible
    if (state === 'visible') {
      gsap.fromTo(
        $lines,
        {
          y: direction === 'up' ? Y_SHIFT : -Y_SHIFT,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 0.6,
          ease: Circ.easeOut,
          stagger: (index, target, list) => {
            return direction === 'up'
              ? 0.2 * index + 0.75
              : 0.2 * (list.length - (index + 1)) + 0.75
          },
        }
      )
    }

    // Hidden
    else if (state === 'hidden') {
      gsap.to($lines, {
        y: direction === 'up' ? -Y_SHIFT : Y_SHIFT,
        opacity: 0,
        duration: 0.6,
        ease: Circ.easeIn,
        stagger: (index, target, list) => {
          return direction === 'up'
            ? 0.2 * index + 0
            : 0.2 * (list.length - (index + 1)) + 0
        },
      })
    }
  }, [ref, lines, state, direction])
}
