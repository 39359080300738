import React from 'react'
import classNames from 'classnames'
import { BtnClose } from '@/components/BtnClose'
import Project from '@/templates/Project'
import * as styles from './ProjectModal.styles.scss'

const ProjectModal = ({ project, onClickClose, isOpen }) => {
  return (
    <div
      className={classNames(styles.ProjectModal, {
        [styles.isOpen]: isOpen,
      })}
    >
      <div className={styles.close}>
        <BtnClose isVisible={isOpen} onClick={onClickClose} />
      </div>
      <Project {...project} isDisabled={!isOpen} />
    </div>
  )
}

export { ProjectModal }
