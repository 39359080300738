import { render, NODE_PARAGRAPH } from 'storyblok-rich-text-react-renderer'

/**
 * render rich text from string
 */

export const getRichHeading = (string) => {
  return render(string, {
    nodeResolvers: { [NODE_PARAGRAPH]: (children) => children },
  })
}
