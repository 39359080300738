import { getRichText } from '@/storyblok/utils/getRichText'
import { getLink } from '@/storyblok/utils/getLink'
import { getFluidImage } from '@/storyblok/utils/getFluidImage'
// import { getMedia } from '@/storyblok/utils/getMedia'
// import { getRichHeading } from '@/storyblok/utils/getRichHeading'
// import { getSlug } from '@/utils/getSlug'
// import { linkResolver } from '@/storyblok/utils/linkResolver'

/**
 * Process Storyblok data that can be passed to block components as props
 *
 * @param {array} data
 * @param {object} location
 */

export const useBloks = (data, location = {}) => {
  const blocks = []

  data.forEach((block, blockIndex) => {
    if (block) {
      // Defaults
      const output = {
        type: block.component,
        uid: block._uid || `block-${blockIndex}`,
        location: location,
        sbEditable: block, // for SbEditable component
      }

      // Profiles
      if (block.component === 'profiles') {
        output.heading = block.heading
        output.items = []
        block.items?.forEach((item) => {
          output.items.push({
            uid: item._uid,
            name: item.name,
            role: item.role,
            body: getRichText(item.body),
            image: getFluidImage(item.image?.filename),
          })
        })
      }

      // Text
      else if (block.component === 'text') {
        output.blocks = []
        block.body?.forEach((item) => {
          const links = []
          item.links.forEach((link) => {
            links.push(
              getLink({
                uid: link._uid,
                title: link.title,
                link: link.link,
                target: link.target,
              })
            )
          })
          output.blocks.push({
            uid: item._uid,
            heading: item.heading,
            description: getRichText(item.description),
            links,
          })
        })
      }

      // Add to output array
      blocks.push(output)
    }
  })

  return blocks
}
