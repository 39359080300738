import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { BtnScroll } from '@/components/BtnScroll'
import { useTransition } from './Page.gsap'
import * as styles from './Page.styles.scss'

const Page = ({ heading, children }) => {
  const ref = useRef()

  useTransition(ref, {
    selectors: {
      header: `.${styles.header}`,
    },
  })

  return (
    <div className={styles.Page} ref={ref}>
      {heading && (
        <div className={styles.header}>
          <h2 className={styles.heading}>{heading}</h2>
          <div className={styles.btnScroll}>
            <BtnScroll isVisible={true}>Scroll for more</BtnScroll>
          </div>
        </div>
      )}
      <div>{children}</div>
    </div>
  )
}

Page.propTypes = {
  heading: PropTypes.node,
  children: PropTypes.node,
}

export default Page
