import { getProject } from '@/storyblok/utils/getProject'

/**
 * Process Storyblok data that can be passed to components as props
 *
 * @param {array} data
 * @param {object} location
 */

export const useProjects = (data, { cta }) => {
  const projects = []

  data.forEach((project) => {
    projects.push({
      ...getProject(project),
      cta,
    })
  })

  return projects
}
