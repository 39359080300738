import { gsap } from 'gsap'
import { useEffect } from 'react'

export const useTransition = (ref, { selectors, isVisible }) => {
  const { text, arrow } = selectors

  useEffect(() => {
    const $text = ref.current.querySelector(text)
    const $arrow = ref.current.querySelector(arrow)

    if (isVisible) {
      gsap.to($text, { opacity: 1, duration: 1 })
      gsap.fromTo(
        $arrow,
        { opacity: 0, y: -10 },
        { opacity: 1, y: 0, duration: 0.75, delay: 0.25 }
      )
    } else {
      gsap.to($text, { opacity: 0, duration: 2 })
      gsap.to($arrow, { opacity: 0, y: 10, duration: 1 })
    }
  }, [ref, text, arrow, , isVisible])
}
