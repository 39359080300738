import { useEffect } from 'react'
import { gsap } from 'gsap'
import { useRecoilValue } from 'recoil'
import { transitionState, readyState } from '@/store'

export const useTransition = (ref) => {
  const transition = useRecoilValue(transitionState)
  const isReady = useRecoilValue(readyState)

  useEffect(() => {
    const $root = ref.current

    let tl = gsap.timeline()
    if (transition === 'ready' || (transition === null && isReady)) {
      tl.to($root, {
        opacity: 1,
        delay: 0,
        duration: 0.25,
        overwrite: true,
      })
    } else if (transition === 'in') {
      tl.to($root, { opacity: 0, overwrite: true })
    }
  }, [ref, transition, isReady])
}
