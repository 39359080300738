import { gsap } from 'gsap'
import { useEffect } from 'react'

export const useTransition = (ref, { selectors, isVisible, hasScrolled }) => {
  const { progress } = selectors

  useEffect(() => {
    const $progress = ref.current.querySelector(progress)

    // Progress circle visibility
    if (hasScrolled && isVisible) {
      gsap.to($progress, { opacity: 1, duration: 1, delay: 1 })
    } else {
      gsap.to($progress, { opacity: 0, duration: 1 })
    }
  }, [ref, progress, isVisible, hasScrolled])
}
