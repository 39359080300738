import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import ArrowSvg from '@/assets/scroll-arrow.inline.svg'
import { useTransition } from './BtnScroll.gsap'
import * as styles from './BtnScroll.styles.scss'

const BtnScroll = ({ children, isVisible }) => {
  const ref = useRef()

  useTransition(ref, {
    isVisible,
    selectors: {
      text: `.${styles.text}`,
      arrow: `.${styles.arrow}`,
    },
  })

  return (
    <div className={styles.BtnScroll} ref={ref}>
      <div className={styles.text}>{children}</div>
      <div className={styles.arrow}>
        <ArrowSvg className={styles.arrow__svg} />
      </div>
    </div>
  )
}

BtnScroll.defaultProps = {
  children: 'Scroll down',
}

BtnScroll.propTypes = {
  children: PropTypes.node,
}

export { BtnScroll }
