import { browserState } from '@/store'
import { useRecoilValue } from 'recoil'
import { useEffect, useState, useRef } from 'react'

const maxWidthD = 1200
const maxWidthM = 480

export const useHeroSize = (aspectRatio) => {
  const { windowWidth } = useRecoilValue(browserState)

  // initialize state with undefined width/height so server and client renders match
  const prevContainerWidth = useRef(null)
  const [heroSize, setHeroSize] = useState({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    // handler to call on window resize
    const handleResize = () => {
      const containerWidth = windowWidth
      if (containerWidth !== prevContainerWidth.current) {
        const width =
          containerWidth >= 768
            ? Math.min(containerWidth * 0.88, maxWidthD)
            : Math.min(containerWidth, maxWidthM)
        const height = width / aspectRatio // width * aspectRatio
        setHeroSize({ width, height })
      }
      prevContainerWidth.current = containerWidth
    }

    // add event listener
    window.addEventListener('resize', handleResize)

    // call handler right away so state gets updated with initial window size
    handleResize()

    // remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, [aspectRatio, windowWidth])

  return heroSize
}
