import { useEffect } from 'react'
import { gsap, Power1 } from 'gsap'

export const useTransition = (ref, { isVisible }) => {
  useEffect(() => {
    const $root = ref.current

    if (isVisible) {
      gsap.to($root, {
        opacity: 1,
        scale: 1,
        delay: 0.5,
        duration: 0.35,
        ease: Power1.easeOut,
      })
    } else {
      gsap.to($root, {
        opacity: 0,
        scale: 0,
        duration: 0.35,
        ease: Power1.easeOut,
      })
    }
  }, [isVisible, ref])
}
